import React from 'react';
import { Helmet } from 'react-helmet';
import { PaperClipIcon } from '@heroicons/react/solid'
import Layout from '../../components/layout/Layout';

export default function Example() {
  return (
     <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>C4Scale - Jobs</title>
          <meta name="description" content="Full Stack Engineer Position at C4Scale" />
        </Helmet>
        <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16 relative">
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      {/* <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Job Title</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Job Description</p>
      </div> */}
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Job Title</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Software Engineer</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Job Description</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">We are looking for a passionate, life long learner, self-driven Software developer to develop some kickass products. As a core member of the technology team, you will play a critical role in creating responsive frontend interfaces, building backend platform services, and streamlining the build infrastructure. Right from the start, you will take significant ownership of parts of the platform and be continuously involved in product planning and strategy. 

This role is for an exciting US based B2B start up who's entire tech stack is being built on AWS with cutting-edge technology stack. Experience with React, Node JS, MongoDB required. Must have strong communication and presentation skills. Should be Creative and innovative in approach.
Good to have: Experience in AWS cloud, Docker</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Experience Required</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">0 to 2+ years of full stack experience with passion, right attitude to learn &amp; contribute</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Location</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">[remote/Chennai]</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Employment Type</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Full time</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Attachments</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="ml-2 flex-1 w-0 truncate">Apply via LinkedIn</span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a href="https://www.linkedin.com/hiring/jobs/2701216967/detail/" target="_blank" rel="noopener noreferrer" className="font-medium text-indigo-600 hover:text-indigo-500">
                      Apply
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>
    </div>
    </div>
    </Layout>
  )
}
